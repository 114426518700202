import React from 'react';
import "./HeatmapInfo.css"

class HeatmapInfo extends React.Component {
    render() {
        const { updated } = this.props; // Access updated from props

        return (
            <React.Fragment>
                <div className="flex justify-between footer">
                    <div className="info w-full">
                        Aktierna är kategoriserade efter sektorer och industrier.<br/>
                        Storleken på lådorna representerar marknadsvärde.<br/>
                        Senast uppdaterad: {updated}<br/>
                    </div>
                    <div className="ml-auto mr-0 flex space-x-px whitespace-nowrap colorMap"
                         data-boxover="cssbody=[tooltip_bdy] cssheader=[tooltip_hdr] header=[1-Day Performance] body=[<table width=300><tr><td class='tooltip_tab'>Color indicates today's stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).</td></tr></table>] offsety=[-70] delay=[500]">
                        <div className="flex h-6 items-center justify-center px-2 text-2xs text-white font-normal"
                             style={{ background: "rgb(246, 53, 56)", minWidth: "50px", textShadow: "rgba(0, 0, 0, 0.25) 0px 1px 0px" }}>
                            -3%
                        </div>
                        <div className="flex h-6 items-center justify-center px-2 text-2xs text-white font-normal"
                             style={{ background: "rgb(191, 64, 69)", minWidth: "50px", textShadow: "rgba(0, 0, 0, 0.25) 0px 1px 0px" }}>
                            -2%
                        </div>
                        <div className="flex h-6 items-center justify-center px-2 text-2xs text-white font-normal"
                             style={{ background: "rgb(139, 68, 78)", minWidth: "50px", textShadow: "rgba(0, 0, 0, 0.25) 0px 1px 0px" }}>
                            -1%
                        </div>
                        <div className="flex h-6 items-center justify-center px-2 text-2xs text-white font-normal"
                             style={{ background: "rgb(65, 69, 84)", minWidth: "50px", textShadow: "rgba(0, 0, 0, 0.25) 0px 1px 0px" }}>
                            0%
                        </div>
                        <div className="flex h-6 items-center justify-center px-2 text-2xs text-white font-normal"
                             style={{ background: "rgb(53, 118, 78)", minWidth: "50px", textShadow: "rgba(0, 0, 0, 0.25) 0px 1px 0px" }}>
                            +1%
                        </div>
                        <div className="flex h-6 items-center justify-center px-2 text-2xs text-white font-normal"
                             style={{ background: "rgb(47, 158, 79)", minWidth: "50px", textShadow: "rgba(0, 0, 0, 0.25) 0px 1px 0px" }}>
                            +2%
                        </div>
                        <div className="flex h-6 items-center justify-center px-2 text-2xs text-white font-normal"
                             style={{ background: "rgb(48, 204, 90)", minWidth: "50px", textShadow: "rgba(0, 0, 0, 0.25) 0px 1px 0px" }}>
                            +3%
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default HeatmapInfo;
