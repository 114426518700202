import React from 'react';
import * as d3 from "d3";
import "./Heatmap.css"

class Heatmap extends React.Component {
    constructor(props) {
        super(props);
        this.font = "Arial";
        this.canvasRef = React.createRef(); // Create a ref for the canvas
        this.aspectRatio = 9 / 16; // Desired aspect ratio (height / width)
    }

    drawRectangle(ctx, leaf) {
        const width = leaf.x1 - leaf.x0;
        const height = leaf.y1 - leaf.y0;
        ctx.strokeRect(
            leaf.x0,
            leaf.y0,
            width,
            height
        )
    }

    fillRectangle(ctx, leaf) {
        const width = leaf.x1 - leaf.x0;
        const height = leaf.y1 - leaf.y0;
        ctx.fillStyle = leaf.data.change_color;
        ctx.fillRect(
            leaf.x0,
            leaf.y0,
            width,
            height
        );
    }

    findIdealFontSize(ctx, text, maxWidth, maxHeight) {
        // Set a tighter max font size based on box height, and establish a minimum size
        const maxFontSize = Math.min(maxHeight * 0.3, 24); // Max 30% of height, max 24px
        const minFontSize = 8; // Minimum font size for very small boxes

        // Dynamically filter font sizes based on maxFontSize, ensuring no sizes below minFontSize
        const scaleFontSizes = [32, 28, 24, 20, 16, 12, 10, 8].filter(size => size <= maxFontSize && size >= minFontSize);
        const fontSizePadding = {32: 2, 28: 2, 24: 2, 20: 1, 16: 1, 12: 1, 10: 1, 8: 0.5};

        for (let size of scaleFontSizes) {
            ctx.font = `${size}px ${this.font}`;
            const textWidth = ctx.measureText(text).width;
            const textHeight = Math.abs(ctx.measureText(text).actualBoundingBoxAscent) + Math.abs(ctx.measureText(text).actualBoundingBoxDescent);

            const widthConstraint = maxWidth - 2 * fontSizePadding[size];
            const heightConstraint = maxHeight - 2 * fontSizePadding[size];

            if (textWidth <= widthConstraint && textHeight <= heightConstraint) {
                return size;
            }
        }
        return minFontSize; // Fallback to the minimum size if none fit
    }


    drawCategory(ctx, leaf) {
        ctx.font = "12px " + this.font;
        ctx.fillStyle = "#ffffff";
        ctx.textAlign = "left";
        ctx.fillText(leaf.data.name, leaf.x0 + 5, leaf.y0 + 15);
    }

    drawText(ctx, leaf) {
        // Set a minimum width and height for displaying text
        const minBoxWidth = 5; // Minimum box width to display text
        const minBoxHeight = 5; // Minimum box height to display text

        const width = leaf.x1 - leaf.x0;
        const height = leaf.y1 - leaf.y0;

        // Only draw text if the box is large enough
        if (width >= minBoxWidth && height >= minBoxHeight && leaf.data.name !== undefined) {
            const nameText = leaf.data.name;
            const fontSize = this.findIdealFontSize(ctx, nameText, width, height);
            ctx.textAlign = "center";
            ctx.fillStyle = "#ffffff";

            // Add shadow settings
            ctx.shadowColor = "rgba(0, 0, 0, 0.6)";  // Dark shadow color with opacity
            ctx.shadowBlur = 2;                       // Blur radius for soft edges
            ctx.shadowOffsetX = 1;                    // Horizontal shadow offset
            ctx.shadowOffsetY = 1;                    // Vertical shadow offset

            // Draw Name Label with shadow
            ctx.font = `${fontSize}px ${this.font}`;
            const nameYPosition = leaf.y0 + height / 2;
            ctx.fillText(nameText, leaf.x0 + width / 2, nameYPosition);

            // Draw Change Percentage with shadow
            const changeText = leaf.data.change_percent + "%";
            ctx.font = `${fontSize * 0.8}px ${this.font}`; // Adjust to 80% for secondary text
            const changeYPosition = nameYPosition + fontSize * 0.9; // Position slightly below main text
            ctx.fillText(changeText, leaf.x0 + width / 2, changeYPosition);

            // Reset shadow settings
            ctx.shadowColor = "transparent";
            ctx.shadowBlur = 0;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;
        }
    }


    componentDidMount() {
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Set canvas size to fill the parent div
        canvas.width = canvas.parentElement.clientWidth;
        // Calculate height based on aspect ratio
        canvas.height = canvas.width * this.aspectRatio;

        // Extract values to find min and max
        const values = this.props.pageContext.data.children.flatMap(sector =>
            sector.children.map(stock => stock.value)
        );
        const minValue = d3.min(values);
        const maxValue = d3.max(values);

        // Define scale to use a linear function y=mx+c to interpolate across the domain and range.
        const scale = d3.scaleLinear()
            .domain([minValue, maxValue])
            .range([1, 100]); // Adjust range as needed for desired area scaling

        // Build hierarchical structure with scaled values
        const root = d3.hierarchy(this.props.pageContext.data)
            .sum(d => scale(d.value)); // Apply scale to each value

        // Configure treemap layout
        const treemap = d3.treemap()
            .size([canvas.width, canvas.height])
            .padding(1)
            .round(true)(root);

        this.drawRectangle(ctx, treemap);

        for (let i = 0; i < treemap.children.length; i++) {
            const category = treemap.children[i];

            for (let j = 0; j < category.children.length; j++) {
                const stock = category.children[j];
                this.drawRectangle(ctx, stock);
                this.fillRectangle(ctx, stock);
                this.drawText(ctx, stock);
            }

            this.drawCategory(ctx, category);
        }
    }

    render() {
        return (
            <React.Fragment>
                <canvas ref={this.canvasRef} style={{width: '100%', height: '100%'}}/>
            </React.Fragment>
        )
    }
}

export default Heatmap;
