import React from 'react';
import {Breadcrumb, Layout} from 'antd';
import {useLocation} from '@reach/router';
import Seo from "../components/seo";
import {menuItems} from '../constants/menuItems';
import HeaderMenu from "../components/HeaderMenu";
import HeatmapInfo from "../components/HeatmapInfo";
import Heatmap from "../components/Heatmap";

const { Content, Footer } = Layout;

const HeatmapPage = ({ pageContext }) => {
    const location = useLocation(); // Get current path
    const selectedKey = menuItems.find(item => location.pathname.startsWith(item.key))?.key || menuItems[0].key;

    return (
        <Layout>
            <Seo title={"Heatmaps | " + pageContext.marketPlace}/>
            <HeaderMenu selectedKey={selectedKey} />

            <Content style={{padding: '0 48px'}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>Heatmaps</Breadcrumb.Item>
                    <Breadcrumb.Item>{pageContext.marketPlace}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="canvas-container">
                    <Heatmap pageContext={pageContext}/> {/* Pass context data */}
                </div>
                <HeatmapInfo updated={pageContext.updated}/>
            </Content>
            <Footer style={{textAlign: 'center'}}>
                aktiedata.com ©{new Date().getFullYear()}
            </Footer>
        </Layout>
);
};

export default HeatmapPage;
